import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Headings from '@/components/Headings'
import AddToCart from '@/components/Cart/AddToCart'
import Image from '@/components/Image'

import { Product as StyledProduct, ImageContainer } from '@/components/ProductGrid/styles'

import { formatAmountForDisplay } from '@/utils/helpers'
import { breakpoints } from '@/utils/styles'

const Product = ({ idx, id, content: { main } }) => {
  return (
    <StyledProduct>
      <ImageContainer>
        {main.productImage && (
          <Image
            imageId={main.productImage.asset._id || main.productImage.asset._ref}
            alt={main.title}
            css={css`
              height: 210px;
            `}
          />
        )}
      </ImageContainer>
      <Viewport>
        <ProductInfo>
          <ProductTitle>{main.title}</ProductTitle>
          <PriceTag>{`${!main.fixedPrice ? 'min. ' : ''}${formatAmountForDisplay(
            main.productPrice
          )}`}</PriceTag>
        </ProductInfo>
        <AddToCart id={id} idx={idx} {...main} />
      </Viewport>
    </StyledProduct>
  )
}

export default React.memo(Product)

const Viewport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  @media (min-width: ${breakpoints.s}px) {
    padding: 0;
  }
`

const ProductInfo = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const ProductTitle = styled(Headings.h3)`
  font-weight: 600;
  font-size: 18px;

  @media (min-width: ${breakpoints.m}px) {
    margin-top: 15px;
  }
`

const PriceTag = styled(Headings.h4)`
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  position: relative;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 14px;
  }
`
