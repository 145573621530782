import React from 'react'
import styled from '@emotion/styled'

import Container from '@/components/Container'
import Product from '@/components/Product'
import { Grid } from './styles'

const ProductGrid = ({ data: {products} }) => {
  return (
    <Section>
      <Container>
        <Grid>
          {products.filter(product => !product.content.main.isHidden).map((product, idx) => (
            <Product key={product.id} idx={idx} {...product} />
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default ProductGrid

const Section = styled.section`
  padding: 80px 0;
`
