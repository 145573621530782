import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { breakpoints } from '@/utils/styles'

const CSSFadeDown = ({ as, children }) => {
  return <Transition>{children}</Transition>
}

export default CSSFadeDown

const fadeDown = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: none;
}
`

const fadeDownMobile = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translateY(140px);
}
`

const Transition = styled.div`
  opacity: 0;
  animation-delay: 0.5s;

  @media (max-width: ${breakpoints.s}px) {
    animation: ${fadeDownMobile} 1s linear both;
  }

  @media (min-width: ${breakpoints.m}px) {
    animation: ${fadeDown} 1s linear both;
  }
`
