import React from 'react'
import styled from '@emotion/styled'
import BlockContent from '@sanity/block-content-to-react'

import Container from '@/components/Container'
import Headings from '@/components/Headings'
import Accordion from '@/components/Accordion'
// import Lists from '@/components/Lists'
import { Serializer } from 'src/utils/serializer'

const Faq = ({ data: { faq, headline, disabled, disableTopPadding } }) => {
  const validate = faq.every((obj) => obj.question === ' ')

  return !validate || disabled ? (
    <Section>
      <Container paddingTop={disableTopPadding ? 0 : 80}>
        <Content>
          {headline && <FaqTitle marignTop="0"> {headline} </FaqTitle>}

          <Accordion>
            {faq
              .filter((fa) => fa.answer !== undefined || fa.question !== undefined)
              .map((fq) => {
                if (!fq.answer) {
                  console.error('Missing blocks')
                  return null
                }

                return (
                  <div key={fq._key} label={fq.question}>
                    <BlockContent blocks={fq.answer} serializers={Serializer} />
                  </div>
                )
              })}
          </Accordion>
        </Content>
      </Container>
    </Section>
  ) : null
}

export default Faq

const Section = styled.section``

const Content = styled.div`
  height: 100%;
  p {
    font-weight: 400;
  }
`

// const Title = styled(Headings.h2)`
//   color: #ffffff;

//   @media (max-width: ${breakpoints.xl}px) {
//     font-size: 45px;
//   }
// `

const FaqTitle = styled(Headings.HeadTitle)`
  font-size: 25px;
  margin-top: 60px;
  margin-bottom: 20px;

  ${({ marignTop }) =>
    marignTop &&
    `
    margin-top: ${marignTop};
  `}
`
