import React from 'react'
import styled from '@emotion/styled'

import { css } from '@emotion/core'
import Container from '@/components/Container'
import Slider from '@/components/Slider'
import Headings from '@/components/Headings'
import Image from '@/components/Image'

import { ImageContainer } from '@/components/ProductGrid/styles'
import mediaqueries from '@/styles/media'

import { breakpoints } from '@/utils/styles'

import { getSettings } from './sliderSettings'

const Team = ({ data: { volunteers, headline }, ...props }) => {
  return (
    <Section {...props}>
      <Container>
        <Title>{headline}</Title>
        <Slider bottom="-40px" color="#00a8a6" {...getSettings(volunteers)}>
          {volunteers.map((volunteer) => {
            return (
              <Item key={volunteer.fullname}>
                {volunteer.image && (
                  <ImageContainer
                    css={css`
                      height: 300px;
                      ${mediaqueries.desktop_medium`
                      height: clamp(300px, 50vh, 700px);
                      margin-bottom: 35px;
                    `}
                    `}
                  >
                    <Image
                      src={volunteer.image}
                      alt={volunteer.fullname}
                      width={315}
                      height={300}
                    />
                  </ImageContainer>
                )}
                <InfoTitle>{volunteer.fullname}</InfoTitle>
                <InfoSubtitle>{volunteer.title}</InfoSubtitle>
              </Item>
            )
          })}
        </Slider>
      </Container>
    </Section>
  )
}

export default Team

const Section = styled.section`
  padding: 80px 0;

  @media (max-width: ${breakpoints.s}px) {
    padding: 60px 0;
  }
`

const Item = styled.div`
  display: flex !important;
  min-height: 100%;
  flex-direction: column;
  padding-right: 14px;
  outline: none;
`

const InfoTitle = styled(Headings.h3)`
  margin-top: 18px;

  @media (min-width: ${breakpoints.l}px) {
    font-size: 20px;
  }

  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
  }

  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
  }
`

const InfoSubtitle = styled(Headings.h4)`
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: 500;
  font-size: 15px;
  color: ${(p) => p.theme.colors.articleText};
  letter-spacing: -0.4px;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
  }
`

const Title = styled(Headings.HeadTitle)`
  text-transform: uppercase;
  font-size: 28px;
`
