import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AccordionSection from './AccordionSection'

class Accordion extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
  }
  static defaultProps = {
    allowMultipleOpen: false,
  }
  constructor(props) {
    super(props)

    const openSections = {}

    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true
      }
    })

    this.state = { openSections }
  }

  onClick = label => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this

    const isOpen = !!openSections[label]

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen,
        },
      })
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen,
        },
      })
    }
  }

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this

    return children.map(child =>
      child.props.visibility !== false ? (
        <AccordionSection
          key={child.props.label}
          isOpen={!!openSections[child.props.label]}
          label={child.props.label}
          onClick={onClick}
        >
          {child.props.children}
        </AccordionSection>
      ) : null
    )
  }
}

export default Accordion
