import React from 'react'
import styled from '@emotion/styled'

import Container from '@/components/Container'
import Banner from '@/components/Banner'
import Headings from '@/components/Headings'
import { breakpoints } from '@/utils/styles'

const PageBanner = ({ data: { image, title }, children }) => {
  return (
    <Banner backgroundImage={image}>
      <Overlay />
      <Container paddingVertical={40}>
        {title && <Title>{title}</Title>}
        {children}
      </Container>
    </Banner>
  )
}

export default PageBanner

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
`
const Title = styled(Headings.h2)`
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 3px 5px 1px rgba(0, 0, 0, 0.2);

  @media (min-width: ${breakpoints.l}px) {
    font-size: 36px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 30px;
  }
`
