import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

function SEO(props) {
  const { children, pagePath, ...other } = props

  return (
    <Helmet {...other}>
      <link rel="icon" type="image/png" href="/icon-72x72.png"></link>
      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `tr`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
