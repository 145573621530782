import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import Headings from '@/components/Headings'
import Image from '@/components/Image'

import { isDefaultLanguage } from '@/components/Language'
import { breakpoints } from '@/utils/styles'
import mediaqueries from '@/styles/media'
import { substrDescription, toPlainText } from '@/utils/helpers'

const PostCard = ({ title, excerpt, postImage, publishedAt, slug, locale }) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }

  const articleDate = new Date(publishedAt).toLocaleDateString(locale, options)

  return (
    <ArticleLink
      to={isDefaultLanguage() ? `/haberler/${slug.current}` : `/no/nyheter/${slug.current}`}
      data-a11y="false"
    >
      <Item>
        <ImageContainer>
          <Image
            imageId={postImage.asset._id || postImage.asset._ref}
            alt={title}
            css={css`
              height: 210px;
            `}
          />
        </ImageContainer>

        <Content>
          <Meta>{articleDate}</Meta>
          <Title>{title}</Title>
          {/* {excerpt && <BlockText blocks={excerpt} />} */}
          {excerpt && <Excerpt>{substrDescription(toPlainText(excerpt), 60)}</Excerpt>}
        </Content>
      </Item>
    </ArticleLink>
  )
}

export default PostCard

const ImageContainer = styled.div`
  position: relative;
  height: 280px;
  margin-bottom: 15px;

  & > div {
    height: 100%;
  }

  ${mediaqueries.tablet`
    height: 200px;
    margin-bottom: 35px;
  `}

  ${mediaqueries.phablet`
    overflow: hidden;
    margin-bottom: 0;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  `}
`

const ArticleLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover h2,
  &:focus h2 {
    color: ${(p) => p.theme.colors.primary};

    &:after {
      background-color: ${(p) => p.theme.colors.primary};
    }
  }

  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -1.5%;
    top: -2%;
    width: 103%;
    height: 104%;
    border: 3px solid ${(p) => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }

  ${mediaqueries.phablet`
    &:hover ${ImageContainer} {
      transform: none;
      box-shadow: initial;
    }

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`

const Content = styled.div`
  ${mediaqueries.tablet`
    padding: 25px;
  `}
`
const Item = styled.div`
  position: relative;

  ${mediaqueries.tablet`
    margin-bottom: 60px;
  `}

  ${mediaqueries.phablet`
    margin-bottom: 40px;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  `}
`

const Title = styled(Headings.h2)`
  position: relative;
  font-size: 21px;
  font-family: ${(p) => p.theme.fonts.title};
  margin-bottom: 16px;
  transition: color 0.3s ease-in-out;
  color: #060606;
  text-decoration: underline;
  text-underline-position: under;

  ${mediaqueries.desktop`
        margin-bottom: 15px;
    `}

  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
  }
`

const Excerpt = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.articleText};
  font-family: ${(p) => p.theme.fonts.body};
  max-width: 415px;
  line-height: 2.6rem;

  ${mediaqueries.phablet`
    max-width: 100%;
  `}

  @media (max-width: ${breakpoints.s}px) {
    font-size: 15px;
  }
`

const Meta = styled.span`
  font-size: 14px;
  font-family: ${(p) => p.theme.fonts.body};
  line-height: 18px;
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 10px;
  display: block;
  text-transform: capitalize;
`
