import React from 'react'
import styled from '@emotion/styled'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { css } from '@emotion/core'
import Image from '@/components/Image'

import Container from '@/components/Container'
import Headings from '@/components/Headings'
import { buildImageObj } from '@/utils/helpers'
import imageUrlFor from '@/utils/image-url'
import { breakpoints } from '@/utils/styles'

const PhotoGallery = ({ photos, headline }) => {
  return (
    <Section>
      <Container paddingVertical={40}>
        <Content>
          <GalleryWrapper>
            {headline && <Title>{headline}</Title>}
            <SimpleReactLightbox>
              <SRLWrapper>
                <Grid>
                  {photos.map((photo) => (
                    <a href={imageUrlFor(buildImageObj(photo.image))} key={photo.title}>
                      <Image
                        imageId={photo.image.asset._id || photo.asset._ref}
                        alt={photo.title}
                        css={css`
                          height: 210px;
                        `}
                      />
                    </a>
                  ))}
                </Grid>
              </SRLWrapper>
            </SimpleReactLightbox>
          </GalleryWrapper>
        </Content>
      </Container>
    </Section>
  )
}

const Section = styled.section``
const Content = styled.div`
  height: 100%;
`

const GalleryWrapper = styled.div`
  & > div {
    margin: 0;
  }
`

const Title = styled(Headings.h3)`
  margin-bottom: 3rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `
    padding-top: ${paddingHorizontal}px;
    padding-bottom: ${paddingHorizontal}px;

    @media (max-width: ${breakpoints.m}px) {
      padding-top: ${paddingHorizontal / 2}px;
      padding-bottom: ${paddingHorizontal / 2}px;
    }

  `}
`

export default PhotoGallery
