import * as React from 'react'
import styled from '@emotion/styled'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import Slider from '@/components/Slider'
import Slide from '@/components/Slide'
import Transitions from '@/components/Transitions'
import Headings from '@/components/Headings'

import { breakpoints } from '@/utils/styles'

import { settings } from './sliderSettings'

const HeroSlider = ({ data: { slideshowSlides, disabled } }) => {
  return !disabled ? (
    <Slider {...settings} nextArrow={<MdChevronRight />} prevArrow={<MdChevronLeft />}>
      {slideshowSlides.map((slide, _key) => (
        <Slide key={slide._key} mainImage={slide.image}>
          <Transitions.CSS.FadeDown>
            <Subtitle>{slide.subtitle}</Subtitle>
          </Transitions.CSS.FadeDown>
          <Transitions.CSS.FadeDown>
            <Title>{slide.headline}</Title>
          </Transitions.CSS.FadeDown>
        </Slide>
      ))}
    </Slider>
  ) : null
}

export default React.memo(HeroSlider)

const Title = styled(Headings.h2)`
  position: relative;
  z-index: 2;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 55px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 28px;
  }
  @media (min-width: ${breakpoints.l}px) {
    font-size: 65px;
    line-height: 1.133;
  }
`

const Subtitle = styled(Headings.h4)`
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
`
