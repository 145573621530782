import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import styled from '@emotion/styled'

import Container from '@/components/Container'

import { Serializer } from 'src/utils/serializer'

export const StandardText = ({ data }) => {
  const { text } = data
  return (
    <Section>
      <Container paddingTop={80}>
        <BlockContent blocks={text} serializers={Serializer} />
      </Container>
    </Section>
  )
}

const Section = styled.section`
  padding: 0 0 30px;
`
