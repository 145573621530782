export const settings = {
  dots: true,
  infinite: true,
  className: 'center',
  centerMode: true,
  centerPadding: '15px',
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        centerPadding: '-15px',
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 0.5,
        centerPadding: '55px',
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 5,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1.5,
        centerPadding: '-20px',
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 1.5,
        centerPadding: '-100px',
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 1.5,
        centerPadding: '-180px',
      },
    },
  ],
}
