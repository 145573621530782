import React from 'react'
import styled from '@emotion/styled'

import Container from '@/components/Container'

import PDFLink from '@/components/PDFLink'

const Document = ({ files, downloadText }) => {
  return (
    <Section>
      <Container paddingVertical={40}>
        <Content>
          {files.map((doc) => (
            <PDFLink
              key={doc.title}
              target="_blank"
              rel="noopener noreferrer"
              data-a11y="false"
              aria-label={`Link to ${doc.file.asset.url}`}
              href={doc.file.asset.url}
              download
            >
              <span>{downloadText}</span>
              <span>{doc.title}</span>
            </PDFLink>
          ))}
        </Content>
      </Container>
    </Section>
  )
}

export default Document

const Section = styled.section`
  padding: 0 0 80px;
`

const Content = styled.div`
  height: 100%;
`
