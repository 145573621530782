import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'
import Headings from '@/components/Headings'

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary onClick={onClick}>
          <Content>
            <Label>{label}</Label>
          </Content>
        </ExpansionPanelSummary>

        {isOpen && <ExpansionPanelDetails>{this.props.children}</ExpansionPanelDetails>}
      </ExpansionPanel>
    )
  }
}

export default AccordionSection

const ExpansionPanel = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: none;
  position: relative;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.87);
`
const ExpansionPanelSummary = styled.div`
  display: flex;
  min-height: 56px;
  margin-bottom: -1px;
  cursor: pointer;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`
const ExpansionPanelDetails = styled.div`
  padding: 0 0 16px;
  p {
    margin-bottom: 0;
  }
`
const Content = styled.div`
  margin: 12px 0;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

const Label = styled(Headings.h5)`
  color: #01a8a6;
`
