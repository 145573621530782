import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'
import mediaqueries from '@/styles/media'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @media (max-width: ${breakpoints.s}px) {
    grid-template-columns: repeat(1, 1fr);
  }

  ${({ paddingHorizontal }) =>
    paddingHorizontal &&
    `
    padding-top: ${paddingHorizontal}px;
    padding-bottom: ${paddingHorizontal}px;

    @media (max-width: ${breakpoints.m}px) {
      padding-top: ${paddingHorizontal / 2}px;
      padding-bottom: ${paddingHorizontal / 2}px;
    }

  `}
`

export const ImageContainer = styled.div`
  position: relative;
  box-shadow: 1px 11px 14px 0px rgb(0 0 0 / 0.15);

  /* & > div {
    height: 200px;
  } */

  ${mediaqueries.desktop_medium`
    height: 200px;
    margin-bottom: 35px;
  `}

  ${mediaqueries.phablet`
    overflow: hidden;
    margin-bottom: 0;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  `}
`

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  transition: transform 0.33s var(--ease-out-quart);

  @media (max-width: ${breakpoints.s}px) {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`

export const Title = styled.span`
  font-weight: 300;
  font-size: 1.2rem;
  text-align: center;
`

export const PriceTag = styled.span`
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  margin-top: 15px;

  :before {
    content: '- ';
  }
`
