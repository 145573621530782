import styled from '@emotion/styled'

const PDFLink = styled.a`
  background: #ffffff;
  border: solid 1px #e6e6e6;
  border-radius: 2px;
  display: inline-block;
  height: 100px;
  line-height: 100px;
  margin: 5px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  min-width: 150px;
  padding: 0 10px;

  &:hover {
    background: #e2e8f0;
    border-color: ${p => p.theme.colors.articleText};
  }
  &:active {
    background: #dae0e8;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  &:before,
  &:after {
    background: #ffffff;
    border: solid 3px ${p => p.theme.colors.articleText};
    border-radius: 4px;
    content: '';
    display: block;
    height: 35px;
    left: 50%;
    margin: -17px 0 0 -12px;
    position: absolute;
    top: 50%;
    /*transform:translate(-50%,-50%);*/

    width: 25px;
  }
  &:hover:after,
  &:hover:before {
    background: #e2e8f0;
  }

  &:before {
    margin: -23px 0 0 -5px;
  }

  &:hover span:first-child {
    display: inline-block;
  }

  &:hover span:last-child {
    display: none;
  }

  span {
    background: ${p => p.theme.colors.secondary};
    border-radius: 4px;
    color: #ffffff;
    display: inline-block;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    padding: 5px 10px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    &:first-of-type {
      display: none;
    }
  }
`
export default PDFLink
