import React from 'react'
import styled from '@emotion/styled'

import Container from '@/components/Container'
import PostCard from '@/components/PostCard'
import Headings from '@/components/Headings'

import mediaqueries from '@/styles/media'

const News = ({ data, paddingVertical, title, locale }) => {
  return (
    <Section paddingVertical={paddingVertical}>
      <Container>
        <Title>{title || data.title}</Title>
        <Grid>
          {data.posts.map((post) => (
            <PostCard key={post.id} locale={locale} prefix={data.title} {...post} />
          ))}
        </Grid>
      </Container>
    </Section>
  )
}

export default News

const Section = styled.section`
  padding: ${({ paddingVertical = '80px' }) => paddingVertical} 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.5rem;

  ${mediaqueries.desktop`
    grid-template-columns: 1fr 1fr;
  `};

  ${mediaqueries.tablet`
    grid-template-columns: 1fr;
  `};

  ${mediaqueries.phablet`
    grid-template-columns: 1fr;
  `};
`

const Title = styled(Headings.HeadTitle)`
  text-transform: uppercase;
  font-size: 28px;
`
