import * as React from 'react'
import styled from '@emotion/styled'

import { useCartContext } from 'context/CartContext'
import { Input } from '@/components/shared/FormElements'

import { QtyFieldset, AddButton } from './styles'

const validate = (count) => {
  return parseInt(count)
}

const AddToCart = ({ idx, id, title, productPrice, productImage, fixedPrice }) => {
  const { cart, cartDispatch } = useCartContext()

  const [price, setPrice] = React.useState(0)

  React.useEffect(() => {
    cart.map((_product) => {
      if (_product.id === id) {
        setPrice(_product.price)
      } else {
        setPrice(0)
      }
    })
  }, [id, cart])

  const handleAddToCard = (fixedPrice) => (e) => {
    const newPrice = validate(e.currentTarget.value)
    const foundProduct = cart.find((product) => product.id === id)

    let nextPrice

    if (newPrice > 0) {
      nextPrice = newPrice

      if (foundProduct) {
        cartDispatch({
          type: 'INCREMENT_PRICE',
          id,
          price: nextPrice,
        })
      } else {
        cartDispatch({
          type: 'ADD_TO_CART',
          product: {
            id,
            title,
            price: nextPrice,
            productImage,
            fixedPrice,
            defaultPrice: productPrice,
          },
        })
      }
    } else {
      cartDispatch({
        type: 'REMOVE_FROM_CART',
        id,
      })
    }
    setPrice(nextPrice)
  }

  const handleIncreasePrice = (productPrice, fixedPrice) => {
    const foundProduct = cart.find((product) => product.id === id)

    let nextPrice

    if (foundProduct) {
      nextPrice = !fixedPrice ? foundProduct.price + 1 : foundProduct.price + productPrice

      cartDispatch({
        type: 'INCREMENT_PRICE',
        id,
        price: nextPrice,
      })
    } else {
      nextPrice = !fixedPrice ? 1 : productPrice

      cartDispatch({
        type: 'ADD_TO_CART',
        product: {
          id,
          title,
          price: nextPrice,
          productImage,
          fixedPrice,
          defaultPrice: productPrice,
        },
      })
    }

    setPrice(nextPrice)
  }

  const handleDecreasePrice = (productPrice, fixedPrice) => {
    const foundProduct = cart.find((product) => product.id === id)

    let nextPrice

    if (foundProduct) {
      nextPrice = !fixedPrice ? foundProduct.price - 1 : foundProduct.price - productPrice

      if (nextPrice > 0) {
        cartDispatch({
          type: 'DECREMENT_PRICE',
          id,
          price: nextPrice,
        })
      } else {
        nextPrice = 0
        // nextPrice = !fixedPrice ? 0 : productPrice
        cartDispatch({
          type: 'REMOVE_FROM_CART',
          id,
        })
      }
    }

    setPrice(nextPrice)
  }

  return (
    <Container>
      <AddButton
        disabled={price === 0}
        // disabled={price === 0 || price === productPrice}
        onClick={() => handleDecreasePrice(productPrice, fixedPrice)}
      >
        —
      </AddButton>
      <QtyFieldset isFixedPrice>
        <Input
          id={idx}
          name={title}
          type="text"
          value={price}
          readonly={fixedPrice}
          disabled={fixedPrice}
          onChange={handleAddToCard(fixedPrice)}
        />
      </QtyFieldset>
      <AddButton
        onClick={() => {
          handleIncreasePrice(productPrice, fixedPrice)
        }}
      >
        +
      </AddButton>
    </Container>
  )
}
export default AddToCart

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
