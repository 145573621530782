import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import Container from '@/components/Container'

import { breakpoints } from '@/utils/styles'
import { buildImageObj } from '@/utils/helpers'
import imageUrlFor from '@/utils/image-url'

const SlideItem = ({ mainImage, children }) => {
  const backgroundImage = imageUrlFor(buildImageObj(mainImage)).blur(1).url()

  return (
    <Background backgroundImage={backgroundImage} defaultFadeIn={700}>
      <Container
        css={css`
          height: 100%;
        `}
      >
        <Item>
          <Content>
            <Info>{children}</Info>
          </Content>
        </Item>
      </Container>
    </Background>
  )
}

export default SlideItem

export const Background = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  transition: opacity 1s linear, visibility 0s linear 5s, left 1s linear;
  opacity: 1 !important;

  ${({ backgroundImage }) =>
    backgroundImage &&
    `
      background:  url(${backgroundImage}) no-repeat center center;
  `}

  ${({ absolute }) =>
    absolute &&
    `
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  `}

  ${({ overlay = false }) =>
    overlay &&
    `
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
      transition: backdrop-filter 0.3s;
      z-index: 1;
    }
    `}

  &:before,
  &:after {
    mix-blend-mode: darken;
  }

  img {
    width: 91%;
  }

  @media (max-width: ${breakpoints.s}px) {
    min-height: 30em;
    height: 100%;
    margin-top: 40px;
    background-size: cover;

    &:before,
    &:after {
      background-position: center center !important;
      background-size: 100% 30em !important;
    }
  }
`
const Item = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (min-width: ${breakpoints.m}px) {
    height: 806px;
  }
`
const Info = styled.div`
  margin-top: 19px;
  font-size: 60px;
  max-width: 775px;
  color: #fff;
`
