import React from 'react'

import { StandardText } from './StandardText'

import HeroSlider from 'src/components/HeroSlider'
import Stats from 'src/components/Stats'
import Team from 'src/components/Team'
import ProductGrid from 'src/components/ProductGrid'
import News from '@/components/News'
import BookShelf from '@/components/BookShelf'
import PageBanner from '@/components/PageBanner'
import Faq from '@/components/Faq'
import Gallery from '@/components/Gallery'
import Documents from '@/components/Documents'

export default ({ reactModule, type, locale }) => {
  switch (type) {
    case 'standardText':
      return <StandardText data={reactModule} locale={locale} />
    case 'productGrid':
      return <ProductGrid data={reactModule} locale={locale} />
    case 'slideshowModule':
      return <HeroSlider data={reactModule} locale={locale} />
    case 'statsModule':
      return <Stats data={reactModule} locale={locale} />
    case 'volunteerModule':
      return <Team data={reactModule} locale={locale} />
    case 'postGrid':
      return <News data={reactModule} locale={locale} />
    case 'booksModule':
      return <BookShelf data={reactModule} locale={locale} />
    case 'imageModule':
      return <PageBanner data={reactModule} locale={locale} />
    case 'faqModule':
      return <Faq data={reactModule} locale={locale} />
    case 'galleryModule':
      return (
        <Gallery headline={reactModule.headline} photos={reactModule.gallery} locale={locale} />
      )
    case 'documentsModule':
      return (
        <Documents
          files={reactModule.files}
          downloadText={reactModule.donwloadText}
          locale={locale}
        />
      )
    default:
      return <span>{type}</span>
  }
}
