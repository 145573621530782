export const getSettings = (volunteers) => ({
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: volunteers.length > 2 ? 3 : 2,
  slidesToScroll: volunteers.length > 2 ? 3 : 2,
  centerPadding: '-15px',
  className: 'center',
  centerMode: true,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: volunteers.length > 2 ? 3 : 2,
        slidesToScroll: volunteers.length > 2 ? 3 : 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 2.2,
        centerPadding: '5px',
        className: 'center',
        centerMode: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: volunteers.length > 2 ? 3 : 2,
        slidesToScroll: 1,
        initialSlide: 5,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '30px',
        className: 'center',
        centerMode: true,
      },
    },
  ],
})
