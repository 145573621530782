import React from 'react'
import styled from '@emotion/styled'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { useStaticQuery, graphql } from 'gatsby'

import Slider from '@/components/Slider'
import Container from '@/components/Container'
import { Img, breakpoints } from '@/utils/styles'
import { settings } from './sliderSettings'

const fluidProps = (mainImage) =>
  mainImage &&
  mainImage.asset &&
  getFluidGatsbyImage(
    mainImage.asset._id || mainImage.asset._ref,
    { width: 960, height: Math.floor((9 / 16) * 960) },
    {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'opj4r4nr',
      dataset: process.env.GATSBY_SANITY_DATASET || 'production',
    }
  )

const BookShelf = ({ data: { book, disabled } }) => {
  const data = useStaticQuery(graphql`
    query {
      shelfBackground: file(relativePath: { eq: "shelf.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return !disabled ? (
    <Section>
      <Ebooks
        style={{
          backgroundImage: `url(${data.shelfBackground.childImageSharp.fluid.src})`,
        }}
      >
        <Container paddingBottom="70">
          <Wrapper>
            <SlickSlider bottom="-70px" color="#00a8a6" {...settings}>
              {book.map((bk) => (
                <Content key={bk.title}>
                  <ExternalLink
                    target="_blank"
                    rel="noopener noreferrer"
                    data-a11y="false"
                    aria-label={`Link to ${bk?.file?.asset?.url}`}
                    href={bk?.file?.asset?.url}
                  >
                    <Image fluid={fluidProps(bk.image)} alt={bk.title} />
                  </ExternalLink>
                </Content>
              ))}
            </SlickSlider>
          </Wrapper>
        </Container>
      </Ebooks>
    </Section>
  ) : null
}
export default BookShelf

const Section = styled.section`
  background-color: #f3f3f3;
  padding: 80px 0;
`

const SlickSlider = styled(Slider)`
  .slick-list .slick-track .slick-slide > div {
    margin: 0 20px;
  }
`

const Ebooks = styled.div`
  margin: 0 auto;
  padding: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  display: flex;
  align-items: center;
  &:before {
    background-size: auto !important;
    background-position: unset !important;
  }

  @media (max-width: ${breakpoints.s}px) {
    background-position: center 95px;
  }
`

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.l}px) {
    width: 650px;
    margin-bottom: -10px;
  }

  @media (min-width: ${breakpoints.m}px) {
    width: 600px;
    margin-bottom: -10px;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 320px;
    margin-top: 10px;
  }
  @media (max-width: 375px) {
    width: 280px;
    margin-top: 0;
  }

  @media (max-width: 320px) {
    width: 250px;
    margin-top: -10px;
  }

  margin: 0 auto;
`

const Image = styled(Img)`
  position: static !important;
`

const Content = styled.div`
  cursor: pointer;
  display: block;
  width: 130px !important;
  height: 180px;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 9px 20px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: box-shadow 0.3s linear;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 9px 20px 0 rgba(0, 0, 0, 0.45);
    ${Image} {
      box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.2);
    }
  }
  &:before,
  &:after {
    content: '';
    display: block;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: -1;
    top: 0;
    border: 1px solid #fde3a7;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:before {
    left: -3px;
  }
  &:after {
    left: -6px;
  }
`

const ExternalLink = styled.a`
  display: inline-block;
`
