import React from 'react'
import styled from '@emotion/styled'
import Carousel from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Slider = ({
  children,
  speed = 500,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrows = false,
  dots = true,
  infinite = true,
  bottom,
  color,
  ...others
}) => {
  return (
    <Container bottom={bottom} color={color}>
      <Carousel
        dots={dots}
        infinite={infinite}
        speed={speed}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        arrows={arrows}
        {...others}
      >
        {children}
      </Carousel>
    </Container>
  )
}

export default Slider

const Container = styled.div`
  height: 100%;
  padding: 0;
  // overflow: hidden;
  width: 100%;
  position: relative;
  vertical-align: top;
  margin-right: auto;
  margin-left: auto;
  /* margin-top: 44px; */

  .slick-prev {
    left: 45px;
    z-index: 1;
    fill: #fdffff;
    width: 6rem;
    height: 6rem;
  }

  .slick-next {
    right: 45px;
    fill: #fdffff;
    width: 6rem;
    height: 6rem;
  }

  .slick-dots {
    bottom: ${({ bottom = '20px' }) => bottom};

    li {
      button:before {
        font-size: 12px;
        color: ${({ color = '#ffffff' }) => color};
      }
      &.slick-active button:before {
        opacity: 1;
      }
    }
  }
`
