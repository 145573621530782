import React from 'react'
import styled from '@emotion/styled'

import Container from '@/components/Container'
import Ticker from '@/components/Ticker'

import { breakpoints } from '@/utils/styles'

const Stats = ({ data: { statistic, disabled, lastUpdated } }) => {
  return !disabled ? (
    <Section>
      <Container>
        <Grid>
          {statistic.map((stats) => (
            <Content key={stats._key}>
              <CounterTitle>{stats.headline}</CounterTitle>
              <CounterSubtitle>{stats.subtitle}</CounterSubtitle>
              <Ticker component={Counter} start={0} end={stats.count} duration={2} separator="." />
            </Content>
          ))}
        </Grid>
        {lastUpdated && <Text>{`${lastUpdated.title}: ${lastUpdated.publishedAt}`}</Text>}
      </Container>
    </Section>
  ) : null
}

export default Stats

const Section = styled.section`
  background-color: #000000;
  padding: 50px 0;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;
  padding: 40px 0;

  @media (min-width: ${breakpoints.m}px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 1rem;
  }
`

const Content = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Counter = styled.span`
  color: ${(p) => p.theme.colors.white};
  font-size: 40px;
  font-weight: 600;
  text-align: left;
`
const CounterTitle = styled.span`
  color: #fefefe;
  display: block;
  font-size: 18px;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 15px;
  }
`
const CounterSubtitle = styled.span`
  font-size: 18px;
  color: #26b5b4;

  @media (min-width: ${breakpoints.m}px) {
    margin-bottom: 20px;
  }
`

const Text = styled.span`
  font-family: ${(p) => p.theme.fonts.title};
  font-size: 16px;
  color: #f7f7f7;
  margin-left: 40px;
`
